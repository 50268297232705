import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Check as CheckIcon, Lock as LockIcon } from "@mui/icons-material";
import { FormHelperText, Stack, Typography } from "@mui/material";

import { PrimaryButton } from "~common/components/controls/buttons";
import { PinInput } from "~common/components/controls/text-fields";
import { useUpdateAnywhereCardPin } from "~common/services/issued-cards";
import { useTracking } from "~common/tracking";
import { selectCatchCard } from "~src/store";
import { setCatchCard } from "~src/store/slices/user-slice";

const CatchCardPinForm: React.VFC = () => {
  const dispatch = useDispatch();
  const { trackEvent, trackError } = useTracking();
  const catchCard = useSelector(selectCatchCard);
  const { mutate: updateAnywhereCardPin } = useUpdateAnywhereCardPin();
  const [pin, setPin] = useState("");
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [complete, setComplete] = useState(catchCard?.is_pin_set || false);

  const handleChange = (value: string) => {
    setPin(value);
    setErrors({});
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (pin.length !== 4) {
      setErrors({
        pin: "Please enter a four digit PIN",
      });

      return;
    }

    if (!catchCard?.card_id) {
      trackError("Catch Card Activation", "PIN", { error: "Missing card_id" });

      setErrors({
        generic: "Something didn't work as expected. Please try again.",
      });

      return;
    }

    setLoading(true);

    try {
      await updateAnywhereCardPin(
        { pin },
        {
          pathParams: { issuedCardId: catchCard?.card_id },
        }
      );

      trackEvent("Catch Card PIN Saved");
      setComplete(true);
      setLoading(false);

      dispatch(
        setCatchCard({
          ...catchCard,
          is_pin_set: true,
        })
      );
    } catch (err) {
      trackError("Catch Card Activation", "PIN", { error: err });
      setLoading(false);

      setErrors({
        generic: "Something didn't work as expected. Please try again.",
      });
    }
  };

  return (
    <Stack spacing={6} component="form" onSubmit={handleSubmit}>
      <Typography>
        Stores might ask for a 4-digit PIN if you use your Catch card with Apple
        Pay. Make sure you can memorize it or write it down.
      </Typography>

      <PinInput
        numInputs={4}
        value={pin}
        updateValue={handleChange}
        error={!!errors.pin}
        errorMessage={errors.pin}
        disabled={loading || complete}
      />

      {errors.generic && (
        <FormHelperText error>{errors.generic}</FormHelperText>
      )}

      <PrimaryButton
        type="submit"
        startIcon={complete ? <CheckIcon /> : <LockIcon />}
        loading={loading}
        disabled={complete}
      >
        {complete ? "Your PIN is set" : "Continue"}
      </PrimaryButton>
    </Stack>
  );
};

export default CatchCardPinForm;
