import React, { useState } from "react";

import { InfoOutlined as InfoOutlinedIcon } from "@mui/icons-material";
import { Link, Stack, Typography } from "@mui/material";

import { useTracking } from "../../tracking";
import { TextButton } from "../controls/buttons";
import { EducationalContentIcon } from "../icons/vector";
import { DefaultDrawer } from "../modals";

const WhyOnlyDebit: React.VFC = () => {
  const [drawerOpened, setDrawerOpened] = useState<boolean>(false);
  const { trackEvent } = useTracking();

  const openWhyDebitDrawer = () => {
    setDrawerOpened(true);
    trackEvent("Clicked Why only debit");
  };

  return (
    <>
      <DefaultDrawer
        anchor="bottom"
        open={drawerOpened}
        onOpen={openWhyDebitDrawer}
        onClose={() => setDrawerOpened(false)}
        fullBleed={false}
        sx={({ zIndex }) => ({
          zIndex: zIndex.modal + 1,
        })}
      >
        <Stack gap={4} sx={{ display: "flex", justifyContent: "flex-start" }}>
          <EducationalContentIcon size="extrasmall" />
          <Typography variant="h1" color="grey.700">
            Paying with debit means higher rewards
          </Typography>
          <Typography variant="bodyLarge" color="grey.600">
            Did you know that paying with a debit instead of credit card helps
            merchants save on processing fees? Catch is able to offer more
            rewards because we help merchants save and then pass those savings
            on to you.
          </Typography>
        </Stack>
      </DefaultDrawer>

      <TextButton
        size="large"
        onClick={openWhyDebitDrawer}
        sx={({ palette }) => ({
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          width: "100%",
          mt: 6,
          "&.MuiButton-text.MuiButton-sizeLarge .label": {
            display: "flex",
            textDecoration: "none",
            fontWeight: 500,
            alignItems: "center",
            color: palette.grey[500],
          },
        })}
      >
        Why only debit?{" "}
        <InfoOutlinedIcon sx={{ ml: 1, height: 20, width: 20 }} />
      </TextButton>
    </>
  );
};

export default WhyOnlyDebit;
