import React from "react";

import { Stack, Typography, useTheme } from "@mui/material";

import CatchCard from "~common/components/cards/CatchCard";
import { PrimaryButton } from "~common/components/controls/buttons";
import WavySection from "~common/components/layout/WavySection";
import { useTracking } from "~common/tracking";

type CatchCardLandingProps = {
  onNext: () => void;
};

const CatchCardLanding: React.VFC<CatchCardLandingProps> = ({ onNext }) => {
  const { palette } = useTheme();
  const { trackEvent } = useTracking();

  const handleClick = () => {
    trackEvent("Catch Card Activate your free Catch card Clicked");
    onNext();
  };

  return (
    <Stack height="100%" pt={1}>
      <Stack spacing={9} alignItems="center" textAlign="center" mb={14}>
        <CatchCard
          sx={{
            width: 200,
            boxShadow: `5px 5px 0px 0px ${palette.tints?.purple?.main || ""}`,
            transform: "rotate(-2.5deg)",
          }}
        />

        <Stack spacing={4}>
          <Typography variant="h1" component="h3">
            The rewards card built
            <br />
            for shoppers
          </Typography>

          <Typography variant="bodyRegular">
            A <strong>free virtual debit card</strong> linked to your normal
            debit card or bank account. Earn rewards daily, with no debt or
            fees.
          </Typography>
        </Stack>

        <PrimaryButton fullWidth onClick={handleClick}>
          Activate your free Catch card
        </PrimaryButton>
      </Stack>

      <WavySection
        backgroundColor={palette.tertiary.main}
        color="common.white"
        mb={-8}
        flexGrow={1}
        sx={{
          mx: {
            zero: -6,
            sm: -8,
          },
          "&:before": {
            pb: {
              zero: "calc(100% / 3 * 0.15)",
              xl: "calc(100% / 3 * 0.15)",
            },
          },
          "& > .MuiBox-root": {
            pt: {
              zero: 13,
              xl: 13,
            },
          },
        }}
      >
        <Typography variant="h1" component="h3" px={6} pb={9}>
          Earn everywhere.
          <br />
          Redeem at Catch brands.
        </Typography>
      </WavySection>
    </Stack>
  );
};

export default CatchCardLanding;
