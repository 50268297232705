import React from "react";

const SVG: React.VFC = () => (
  <svg
    width="80"
    height="80"
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M51.21 19.6798L54.8678 16.1987"
      stroke="#232639"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M27.9569 19.5555L24.4297 16.1987"
      stroke="#232639"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M39.1914 15.7008V9.98193"
      stroke="#232639"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M36.4483 57.7223C36.5789 51.8791 36.5789 45.1656 36.7095 37.9549C36.8402 31.7387 34.6193 30.4954 32.7904 31.6144C30.7002 32.8576 31.3534 39.4467 39.1916 40.4413C47.0299 41.5602 50.6877 33.2306 47.2911 31.863C43.8946 30.4954 42.3269 37.9549 41.6737 43.3008C41.0206 48.6467 40.498 57.598 40.498 57.598"
      stroke="#232639"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M44.417 55.733C45.0702 52.0033 51.7327 44.5439 53.5616 40.3169C54.9986 37.0845 55.2599 33.2304 53.8229 29.6251C50.9489 21.917 42.0655 18.063 33.966 20.7981C25.8665 23.5332 21.8168 31.9872 24.6908 39.6953C25.9972 43.9223 32.6597 52.0033 33.0516 55.8573V57.7222V63.0681C33.0516 66.0518 35.5337 68.5383 38.669 68.5383C41.8043 68.5383 44.417 66.1762 44.417 63.1924V58.0951V55.733Z"
      stroke="#232639"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M33.1826 61.5763L44.2868 61.3276"
      stroke="#232639"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M33.1826 64.4355L43.6336 65.8031"
      stroke="#232639"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M33.3125 57.8462H44.1554"
      stroke="#232639"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SVG;
