import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Stack, TextField } from "@mui/material";

import {
  MailcheckErrorHelperText,
  MailcheckInformationalAlert,
} from "~common/components/alerts";
import RewardCard from "~common/components/cards/RewardCard";
import { PrimaryButton } from "~common/components/controls/buttons";
import type { UserResponseData } from "~common/services";
import { convertRawServiceError } from "~common/services/error-handling";
import { useUpdateCurrentUser } from "~common/services/users";
import { useTracking, useTrackPageView } from "~common/tracking";
import {
  EMAIL_IN_USE_MESSAGE,
  INVALID_EMAIL_MESSAGE,
  isValidEmailAddress,
  runMailcheck,
} from "~common/utils/email";
import {
  EMPTY_FIRST_NAME_MESSAGE,
  EMPTY_LAST_NAME_MESSAGE,
  trimName,
} from "~common/utils/names";
import SmallPagePanel from "~src/components/layout/SmallPagePanel";
import { selectClaims, selectPrefill } from "~src/store";
import { currentUserActions } from "~src/store/slices/services/currentUser-slice";
import { markEmailVerified, setAuthState } from "~src/store/slices/user-slice";

type EmailError = "email-in-use" | "invalid-email" | "mailcheck" | null;

const CompleteAccount: React.VFC = () => {
  const { trackEvent, trackError, captureException, trackUser } = useTracking();
  const { mutate: updateCurrentUser } = useUpdateCurrentUser();
  const dispatch = useDispatch();
  const { rewardCampaign, gift } = useSelector(selectClaims);
  const { userFirstName, userLastName, userEmail } = useSelector(selectPrefill);
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [firstName, setFirstName] = useState<string>(userFirstName || "");
  const [lastName, setLastName] = useState<string>(userLastName || "");
  const [hasNameError, setHasNameError] = useState<boolean>(false);
  const [email, setEmail] = useState<string>(userEmail || "");
  const [emailErrorType, setEmailErrorType] = useState<EmailError>(null);
  const [incorrectEmail, setIncorrectEmail] = useState<string | null>(null);

  useTrackPageView("Create Account");

  const dispatchUserUpdates = (currentUser: UserResponseData) => {
    dispatch(currentUserActions.manualSet(currentUser));

    if (currentUser.refreshed_auth_info) {
      // Update JWT to make sure updated data present in API calls
      // e.g. to claim gifts as a new user.
      // Also marks email and verification status, which triggers
      // the navigation to the next page.
      dispatch(setAuthState(currentUser.refreshed_auth_info));
    }

    // We want users who create their account via the user portal to be able
    // to immediately enter the user portal without verifying their email. In order to do so,
    // we will mark them as verified in the Redux store when they complete their account
    // (the DB state will still reflect that the user is not yet verified so that they will
    // have to verify their email for any future logins)
    dispatch(markEmailVerified());
  };

  const hasInvalidEmail = () =>
    !!emailErrorType && emailErrorType !== "mailcheck";
  const hasMailcheckError = () => emailErrorType === "mailcheck";

  const emailHelperText = () => {
    if (emailErrorType && emailErrorType === "email-in-use") {
      return EMAIL_IN_USE_MESSAGE;
    }
    if (hasInvalidEmail()) {
      return INVALID_EMAIL_MESSAGE;
    }
    if (hasMailcheckError() && incorrectEmail) {
      return <MailcheckErrorHelperText incorrectEmail={incorrectEmail} />;
    }
    return null;
  };

  const handleNameChange = (name: string, setName: (value: string) => void) => {
    setName(name);
    setHasNameError(false);
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
    // Don't clear mailcheck error message on change.
    if (
      emailErrorType === "email-in-use" ||
      emailErrorType === "invalid-email"
    ) {
      setEmailErrorType(null);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (isProcessing) {
      return;
    }

    setIsProcessing(true);

    const isValidName = !!(firstName && lastName);
    const isValidEmail = isValidEmailAddress(email);

    let canProceed = true;

    if (!isValidName) {
      trackEvent("Name validation error");
      setHasNameError(true);
      canProceed = false;
    }

    if (!isValidEmail) {
      trackEvent("Email validation error");
      setIncorrectEmail(null);
      setEmailErrorType("invalid-email");
      trackError("CompleteAccount", "Email is invalid", {
        email,
      });
      canProceed = false;
    } else {
      const mailcheckSuggestion = runMailcheck(email);
      // We only want to replace the user's incorrect email with mailcheck's solution one time
      // unless they replace the email with a different one entirely.
      if (
        mailcheckSuggestion &&
        (!incorrectEmail || email !== incorrectEmail)
      ) {
        setEmailErrorType("mailcheck");
        setIncorrectEmail(email);
        setEmail(mailcheckSuggestion);

        trackEvent("Email Suggestion Shown", {
          suggestedEmail: mailcheckSuggestion,
          userTyped: incorrectEmail,
        });
        canProceed = false;
      }
    }

    if (canProceed) {
      // Update our db with the user's full name and email address,
      // and update in app state for the auth navigator to work.

      trackEvent("Attempting to update user name and email");
      try {
        // Note: this call also sends a new email verification code if the
        // user is not on sandbox.
        const currentUser = await updateCurrentUser({
          email,
          first_name: trimName(firstName),
          last_name: trimName(lastName),
          new_user: true,
        });
        trackUser({
          email,
          name: `${firstName} ${lastName}`,
        });
        trackEvent("Updating User Name and Email");

        dispatchUserUpdates(currentUser);
      } catch (rawError) {
        const errorData = convertRawServiceError(rawError);
        if (errorData.error_type === "EMAIL_IN_USE") {
          // Clear mailcheck suggestion if email is in use.
          setIncorrectEmail(null);
          setEmailErrorType("email-in-use");
          trackError(
            "CompleteAccount",
            "Email already associated with an account",
            { email, errorData }
          );
        } else {
          captureException({
            component: "CompleteAccount",
            exceptionMessage: "Unknown issue updating user",
            rawError,
          });
        }
      }
    }

    setIsProcessing(false);
  };

  let rewardCard = null;

  if (gift) {
    rewardCard = <RewardCard reward={gift} sx={{ width: 224, mb: 8 }} />;
  }

  if (rewardCampaign) {
    rewardCard = (
      <RewardCard reward={rewardCampaign} sx={{ width: 224, mb: 8 }} />
    );
  }

  return (
    <form onSubmit={handleSubmit} noValidate>
      <SmallPagePanel
        icon={
          <Stack width="100%" alignItems="center">
            {rewardCard}
          </Stack>
        }
        title="Complete your account"
        subtitle="Since it's your first time using Catch, we need your name and email to complete your account setup."
      >
        <Stack
          spacing={6}
          sx={{
            "& .MuiAlert-filledInfo.MuiAlert-filled": { mt: 4 },
          }}
        >
          <TextField
            label="First name"
            value={firstName}
            type="text"
            error={hasNameError && !firstName}
            helperText={
              hasNameError && !firstName ? EMPTY_FIRST_NAME_MESSAGE : ""
            }
            onChange={(e) => handleNameChange(e.target.value, setFirstName)}
            autoFocus
          />

          <TextField
            label="Last name"
            value={lastName}
            type="text"
            error={hasNameError && !lastName}
            helperText={
              hasNameError && !lastName ? EMPTY_LAST_NAME_MESSAGE : ""
            }
            onChange={(e) => handleNameChange(e.target.value, setLastName)}
          />

          <TextField
            label="Email"
            value={email}
            type="email"
            error={hasInvalidEmail()}
            helperText={emailHelperText()}
            onChange={handleEmailChange}
          />

          {hasMailcheckError() && <MailcheckInformationalAlert />}

          <PrimaryButton type="submit" loading={isProcessing} fullWidth>
            {hasMailcheckError() ? "Looks good" : "Continue"}
          </PrimaryButton>
        </Stack>
      </SmallPagePanel>
    </form>
  );
};

export default CompleteAccount;
