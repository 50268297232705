import React from "react";
import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";

import GenericLoadingScreen from "~common/components/loading/GenericLoadingScreen";
import {
  selectAuthSession,
  selectAuthUserInfo,
  selectEmailVerificationCode,
  selectIsAuthed,
  selectIsAuthedVerified,
  selectIsLoading,
} from "~src/store";

type AppRouteHandlerProps = {
  authRequired?: boolean;
  authFlow?: boolean;
};

// This component controls redirects and refresh loading before rendering
// the actual component for a given route, and thereby ensures the user
// is seeing the right component for their route and local state.

const AppRouteHandler: React.FC<AppRouteHandlerProps> = ({
  authRequired = false,
  authFlow = false,
  children,
}) => {
  const isAuthedVerified = useSelector(selectIsAuthedVerified);
  const authSession = useSelector(selectAuthSession);
  const isAuthed = useSelector(selectIsAuthed);
  const authUserInfo = useSelector(selectAuthUserInfo);
  const isLoading = useSelector(selectIsLoading);
  const emailVerificationCode = useSelector(selectEmailVerificationCode);
  const location = useLocation();

  if (isLoading) {
    return <GenericLoadingScreen isFullscreen />;
  }

  // Path routes for navigation.
  const to = {
    Home: "/home",
    SignIn: "/sign-in",
    AnswerOTP: "/sign-in/answer-otp",
    CompleteAccount: "/sign-in/complete-account",
    VerifyEmail: "/sign-in/verify-email",
    CheckEmailVerification: (code: string) => `/verify/email/${code}`,
  };

  if (authRequired && !isAuthedVerified) {
    return <Navigate to={to.SignIn} replace />;
  }

  // We need to check whether the redirect is idempotent in a nested
  // condition to (a) preserve the conditiona flow of else ifs and
  // (b) allow the idempotent page to return {children}.

  const alreadyAt = (path: string) => location.pathname === path;

  if (authFlow) {
    if (!isAuthed && !authSession) {
      if (!alreadyAt(to.SignIn)) {
        return <Navigate to={to.SignIn} replace />;
      }
    } else if (!isAuthed) {
      if (!alreadyAt(to.AnswerOTP)) {
        return <Navigate to={to.AnswerOTP} replace />;
      }
    } else if (!authUserInfo || !authUserInfo.email) {
      if (!alreadyAt(to.CompleteAccount)) {
        return <Navigate to={to.CompleteAccount} replace />;
      }
    } else if (!isAuthedVerified && !emailVerificationCode) {
      if (!alreadyAt(to.VerifyEmail)) {
        return <Navigate to={to.VerifyEmail} replace />;
      }
    } else if (!isAuthedVerified && emailVerificationCode) {
      return (
        <Navigate
          to={to.CheckEmailVerification(emailVerificationCode)}
          replace
        />
      );
    } else {
      return <Navigate to={to.Home} replace />;
    }
  }

  return <>{children}</>;
};

AppRouteHandler.defaultProps = {
  authRequired: false,
  authFlow: false,
};

export default AppRouteHandler;
