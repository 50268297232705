import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import jwt_decode from "jwt-decode";

import {
  NO_EXPERIMENTS_NAME,
  UserPortalActiveExperiments,
  UserPortalExperimentPayload,
} from "~common/experimentation/shared";
import {
  AuthResponseData,
  GiftResponseData,
  PublicUserDataResponseData,
  RewardCampaignClaimSource,
  RewardCampaignResponseData,
} from "~common/services";
import { AnywhereCardResponseData } from "~common/services/types/issued-cards-types";

type AuthSession = {
  id: string;
  phoneNumber: string;
};

type JwtData = {
  email: string;
  email_verified: boolean;
  "custom:user_id": string;
};

type UserInfo = {
  email?: string;
  emailVerified?: boolean;
  userId: string;
  intercomUserHash: string;
};

type Claims = {
  referralCode?: string;
  giftSecret?: string;
  gift?: GiftResponseData;
  rewardCampaignId?: string;
  rewardCampaign?: RewardCampaignResponseData & { claimed?: boolean };
  rewardCampaignClaimSource?: RewardCampaignClaimSource;
  isSecondChanceBonusSignUp?: boolean;
};

type UserState = {
  // Auth id/access token data returned by the server
  // (set to null if the user is unauthed).
  authData: AuthResponseData | null;
  // Internal info about the authed user decoded from their auth id_token
  // (set to null if the user is unauthed).
  authUserInfo: UserInfo | null;
  // Temporary session data we keep track of across "Sign In" and "Answer OTP"
  // once the user has initiated an auth flow.
  authSession: AuthSession | null;
  // Public user data returned by the server if the user has a recognizable
  // device token. This data may be available even if the user is unauthed.
  publicData: PublicUserDataResponseData | null;
  // Whether to display UI indicating that the user has been
  // automatically logged out.
  showLoggedOutNotice: boolean;
  // Temporary store for referral/gift/campaign details if a user needs to
  // first go through the auth flow before checking and claiming.
  claims: Claims;
  // Email verification code, held through auth redirects if necessary.
  emailVerificationCode: string | null;
  // Whether or not to display the reward boost dialog
  displayRewardBoostDialog: boolean;
  // Whether the user has already seen the reward boost dialog
  dismissedRewardBoostDialog: boolean;
  experiments: UserPortalActiveExperiments;
  catchCard?: AnywhereCardResponseData;
  authPhone?: string | null;
};

const initialState: UserState = {
  authData: null,
  authUserInfo: null,
  authSession: null,
  publicData: null,
  showLoggedOutNotice: false,
  claims: {},
  emailVerificationCode: null,
  displayRewardBoostDialog: false,
  dismissedRewardBoostDialog: false,
  experiments: {},
};

const slice = createSlice({
  name: "user",
  initialState,
  reducers: {
    // Sets `authData` and `authUserInfo` in tandem.
    setAuthState: (state, action: PayloadAction<AuthResponseData>) => {
      const { payload } = action;
      const jwtData: JwtData = jwt_decode(payload.id_token);
      state.authData = payload;
      state.authUserInfo = {
        email: jwtData.email,
        emailVerified: jwtData.email_verified,
        intercomUserHash: payload.intercom_user_id_hash,
        userId: jwtData["custom:user_id"],
      };
    },
    setAuthUserInfoEmail: (state, action: PayloadAction<UserInfo["email"]>) => {
      const { payload } = action;
      if (!state.authUserInfo) return;
      state.authUserInfo.email = payload;
    },
    // Clears `authData` and `authUserInfo` in tandem.
    clearAuthState: (state) => {
      state.authData = null;
      state.authUserInfo = null;
      state.claims = {};
    },
    setAuthSession: (state, action: PayloadAction<AuthSession>) => {
      state.authSession = action.payload;
    },
    clearAuthSession: (state) => {
      state.authSession = null;
    },
    setPublicData: (
      state,
      action: PayloadAction<PublicUserDataResponseData>
    ) => {
      state.publicData = action.payload;
    },
    markEmailVerified: (state) => {
      if (!state.authUserInfo) return;
      state.authUserInfo.emailVerified = true;
    },
    setShowLoggedOutNotice: (state, action: PayloadAction<boolean>) => {
      state.showLoggedOutNotice = action.payload;
    },
    setClaimedReferralCode: (
      state,
      action: PayloadAction<Claims["referralCode"]>
    ) => {
      state.claims.referralCode = action.payload;
    },
    setClaimedGiftSecret: (
      state,
      action: PayloadAction<Claims["giftSecret"]>
    ) => {
      state.claims.giftSecret = action.payload;
    },
    setClaimedGift: (state, action: PayloadAction<Claims["gift"]>) => {
      state.claims.gift = action.payload;
    },
    clearGiftClaim: (state) => {
      state.claims.gift = undefined;
      state.claims.giftSecret = undefined;
    },
    setClaimedCampaignId: (
      state,
      action: PayloadAction<Claims["rewardCampaignId"]>
    ) => {
      state.claims.rewardCampaignId = action.payload;
    },
    setClaimedCampaign: (
      state,
      action: PayloadAction<Claims["rewardCampaign"]>
    ) => {
      state.claims.rewardCampaign = action.payload;
    },
    setClaimedCampaignSource: (
      state,
      action: PayloadAction<Claims["rewardCampaignClaimSource"]>
    ) => {
      state.claims.rewardCampaignClaimSource = action.payload;
    },
    setClaimedCampaignStatus: (state, action: PayloadAction<boolean>) => {
      if (!state.claims.rewardCampaign) return;
      state.claims.rewardCampaign.claimed = action.payload;
    },
    clearCampaignClaim: (state) => {
      state.claims.rewardCampaign = undefined;
      state.claims.rewardCampaignId = undefined;
    },
    setClaimedSecondChanceBonusSignUp: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.claims.isSecondChanceBonusSignUp = action.payload;
    },
    setEmailVerificationCode: (state, action: PayloadAction<string | null>) => {
      state.emailVerificationCode = action.payload;
    },
    clearClaims: (state) => {
      state.claims = {};
      state.emailVerificationCode = null;
    },
    markDisplayRewardBoostModal: (state) => {
      state.displayRewardBoostDialog = true;
    },
    dismissDisplayRewardBoostModal: (state) => {
      state.displayRewardBoostDialog = false;
      state.dismissedRewardBoostDialog = true;
    },
    setExperiment: (
      state,
      action: PayloadAction<UserPortalExperimentPayload>
    ) => {
      if (action.payload.experimentName === NO_EXPERIMENTS_NAME) {
        state.experiments[NO_EXPERIMENTS_NAME] = action.payload.experimentGroup;
      }
    },
    setCatchCard: (state, action: PayloadAction<AnywhereCardResponseData>) => {
      state.catchCard = action.payload;
    },
    setAuthPhone: (state, action: PayloadAction<string | null>) => {
      state.authPhone = action.payload;
    },
  },
});

export const {
  setAuthState,
  setAuthUserInfoEmail,
  clearAuthState,
  setAuthSession,
  clearAuthSession,
  setPublicData,
  markEmailVerified,
  setShowLoggedOutNotice,
  setClaimedReferralCode,
  setClaimedGiftSecret,
  setClaimedGift,
  setClaimedCampaignId,
  setClaimedCampaignSource,
  setClaimedCampaign,
  setClaimedCampaignStatus,
  setClaimedSecondChanceBonusSignUp,
  setExperiment,
  markDisplayRewardBoostModal,
  dismissDisplayRewardBoostModal,
  setEmailVerificationCode,
  clearGiftClaim,
  clearCampaignClaim,
  clearClaims,
  setCatchCard,
  setAuthPhone,
} = slice.actions;
export default slice.reducer;
