import React from "react";

import { Link, Stack, Typography } from "@mui/material";

const CatchCardDisclosures: React.VFC = () => (
  <Stack
    spacing={5}
    color="grey.500"
    sx={({ palette, shape }) => ({
      background: palette.grey[200],
      p: 2,
      borderRadius: `${shape.borderRadius * 2}px`,
    })}
  >
    <Typography variant="bodySmall">
      Catch is a financial technology company, not an FDIC insured depository
      institution. Banking services provided by Bangor Savings Bank, Member
      FDIC.
    </Typography>

    <Typography variant="bodySmall">
      The Catch Mastercard® Debit Card is issued by Bangor Savings Bank, Member
      FDIC, pursuant to license by Mastercard International Incorporated.
      Mastercard is a registered trademark, and the circle design is a trademark
      of Mastercard International Incorporated. Spend anywhere Mastercard is
      accepted.
    </Typography>

    <Typography variant="bodySmall">
      By providing Catch your email address you consent to our using the email
      address to send you Service-related notices and agree to our{" "}
      <Link
        href="https://www.getcatch.com/e-sign-agreement"
        target="_blank"
        rel="noopener noreferrer"
        fontWeight="500"
      >
        Electronic Communication and E-Sign Agreement
      </Link>
      , including any notices required by law, in lieu of communication by
      postal mail.
    </Typography>
  </Stack>
);

export default CatchCardDisclosures;
