import { useEnv } from "../contexts/env-context";
import { normalizeUrl } from "../utils/urls";

const useMerchantAssetsUrl = (merchantId: string): string => {
  const env = useEnv();
  return `${env.EXTERNAL_ASSETS_BASE_URL}/merchant-assets/${merchantId}`;
};

const useMerchantBackgroundUrl = (
  merchantId: string,
  merchantBackgroundImage?: string | null
): string => {
  const env = useEnv();

  if (merchantId === "FLEX_MERCHANT_ID") {
    return `${env.EXTERNAL_ASSETS_BASE_URL}/static_assets/imagery/catch_cash_background_full.png`;
  }

  return merchantBackgroundImage
    ? `${normalizeUrl(merchantBackgroundImage)}`
    : "";
};

const useMerchantLogoUrl = (merchantId: string): string => {
  const env = useEnv();

  if (merchantId === "FLEX_MERCHANT_ID") {
    return `${env.EXTERNAL_ASSETS_BASE_URL}/static_assets/imagery/catch_cash_logo.png`;
  }

  return `${env.EXTERNAL_ASSETS_BASE_URL}/merchant-assets/${merchantId}/card_logo.png`;
};

export { useMerchantAssetsUrl, useMerchantBackgroundUrl, useMerchantLogoUrl };
