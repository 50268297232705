import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Lock as LockIcon } from "@mui/icons-material";
import { Box, FormHelperText, Stack, Typography } from "@mui/material";

import { PrimaryButton } from "~common/components/controls/buttons";
import {
  useGetUserLatestBillingAddress,
  useUpdateCurrentUser,
} from "~common/services/users";
import { useTracking } from "~common/tracking";
import { selectCurrentUser } from "~src/store";
import { currentUserActions } from "~src/store/slices/services/currentUser-slice";
import { getAddressForCatchCardApplication } from "~src/utils/address";

import EditCard from "../shared/EditCard";
import AddressAutocomplete from "./AddressAutocomplete";

type CatchCardAddressFormProps = {
  onNext: () => void;
  onEdit: (isActive: boolean, hideCancel?: boolean) => void;
  isEditing: boolean;
};

const CatchCardAddressForm: React.VFC<CatchCardAddressFormProps> = ({
  onNext,
  onEdit,
  isEditing,
}) => {
  const dispatch = useDispatch();
  const { trackEvent, trackError } = useTracking();
  const currentUser = useSelector(selectCurrentUser.data);
  const { mutate: updateCurrentUser } = useUpdateCurrentUser({
    queryParams: { include_billing_address: true },
  });
  const { data: latestAddress, loading: loadingLatestAddress } =
    useGetUserLatestBillingAddress();
  const [address, setAddress] = useState(currentUser?.billing_address || null);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<Record<string, string>>({});

  useEffect(() => {
    onEdit(!address, true);
  }, [address, onEdit]);

  useEffect(() => {
    if (!currentUser?.billing_address && latestAddress?.latest_checkout_billing_address) {
      setAddress(
        getAddressForCatchCardApplication(
          latestAddress.latest_checkout_billing_address
        )
      );
    }
  }, [currentUser, latestAddress]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!address) {
      return;
    }

    if (address.address_id) {
      onNext();
      return;
    }

    setLoading(true);

    try {
      await updateCurrentUser({
        billing_address: address,
      }).then((response) => {
        dispatch(currentUserActions.manualSet(response));
      });

      trackEvent("Catch Card Billing Address Saved");
      onNext();
    } catch (err) {
      setLoading(false);
      trackError("Catch Card Activation", "Billing address", { error: err });

      setErrors({
        generic: "Something didn't work as expected. Please try again.",
      });
    }
  };

  if (loadingLatestAddress) {
    return null;
  }

  return (
    <>
      {isEditing ? (
        <Stack spacing={6}>
          <Typography>What&apos;s your current billing address?</Typography>
          <AddressAutocomplete onSelect={setAddress} />
        </Stack>
      ) : (
        <Stack spacing={6} component="form" onSubmit={handleSubmit}>
          <Typography>Is this your current billing address?</Typography>

          <EditCard
            onClick={address?.address_id ? undefined : () => onEdit(true)}
          >
            <Box>
              {address?.address_1}
              {address?.address_2 && `, ${address.address_2}`}
            </Box>

            <Box>
              {address?.city}, {address?.zone_code} {address?.postal_code},{" "}
              {address?.country_code}
            </Box>
          </EditCard>

          {errors.generic && (
            <FormHelperText error>{errors.generic}</FormHelperText>
          )}

          <PrimaryButton
            type="submit"
            startIcon={<LockIcon />}
            loading={loading}
          >
            Confirm address
          </PrimaryButton>
        </Stack>
      )}
    </>
  );
};

export default CatchCardAddressForm;
