import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Lock as LockIcon } from "@mui/icons-material";
import { Link, Stack, Typography } from "@mui/material";

import AdyenCardConnect from "~common/components/bank-linking/AdyenCardConnect";
import CatchCardDisclosures from "~common/components/bank-linking/CatchCardDisclosures";
import PaymentInstrumentErrorHeaders from "~common/components/bank-linking/connect/headers/payment-instrument/PaymentInstrumentErrorHeaders";
import { PrimaryButton } from "~common/components/controls/buttons";
import {
  setConnectBankVariant,
  setIsCatchPassFlow,
} from "~common/store/slices/bankLinking-slice";
import { useTracking } from "~common/tracking";
import usePaymentInstruments from "~src/hooks/services/usePaymentInstruments";
import { selectConnectBankVariant } from "~src/store";

import EditCard from "../shared/EditCard";

type CatchCardLinkDebitProps = {
  onNext: () => void;
  onEdit: (isActive: boolean) => void;
  isEditing: boolean;
};

const CatchCardLinkDebit: React.VFC<CatchCardLinkDebitProps> = ({
  onNext,
  onEdit,
  isEditing,
}) => {
  const dispatch = useDispatch();
  const { trackEvent } = useTracking();
  const { paymentInstruments } = usePaymentInstruments();
  const connectBankVariant = useSelector(selectConnectBankVariant);
  const paymentInstrument = paymentInstruments[0] || null;

  useEffect(() => {
    onEdit(!paymentInstrument);
  }, [paymentInstrument, onEdit]);

  useEffect(() => {
    dispatch(setIsCatchPassFlow(true));

    return () => {
      dispatch(setIsCatchPassFlow(false));
    };
  }, [dispatch]);

  const handleRetry = () => {
    trackEvent("Catch Card Try again Clicked", {
      component: "CatchCardLinkDebit",
    });

    dispatch(
      setConnectBankVariant(paymentInstrument ? "ChangeMethod" : "Default")
    );
  };

  const handleConfirm = () => {
    trackEvent("Catch Card Confirm debit card Clicked");
    onNext();
  };

  if (
    connectBankVariant !== "Default" &&
    connectBankVariant !== "ChangeMethod"
  ) {
    return (
      <Stack
        spacing={6}
        sx={{
          ".BankLinking-header": {
            textAlign: "center",
            alignItems: "center",
          },
        }}
      >
        <PaymentInstrumentErrorHeaders variant={connectBankVariant} />
        <PrimaryButton onClick={handleRetry}>Try again</PrimaryButton>
      </Stack>
    );
  }

  return (
    <Stack spacing={6}>
      <Typography>
        Your Catch card is a free virtual debit card that&apos;s linked to your
        normal debit card, so we can grant and redeem your rewards.
      </Typography>

      {isEditing ? (
        <AdyenCardConnect inline />
      ) : (
        <>
          <EditCard onClick={() => onEdit(true)}>
            {paymentInstrument?.display_name}
          </EditCard>

          <PrimaryButton startIcon={<LockIcon />} onClick={handleConfirm}>
            Continue
          </PrimaryButton>

          <Stack spacing={6}>
            <Stack spacing={5} color="grey.500">
              <Typography variant="bodySmall">
                By selecting &quot;Continue&quot; above, you authorize Catch to
                initiate electronic payments from your{" "}
                {paymentInstrument?.institution_name}{" "}
                {paymentInstrument?.account_type === "debit"
                  ? "card"
                  : "account"}{" "}
                for any amount up to the total captured on your virtual card on
                or after{" "}
                {new Date().toLocaleDateString("en-US", {
                  month: "long",
                  day: "numeric",
                  year: "numeric",
                })}
                .
              </Typography>

              <Typography variant="bodySmall">
                Authorization can be revoked by reaching out to customer support
                at{" "}
                <Link
                  href="mailto:help@getcatch.com"
                  color="inherit"
                  fontWeight="500"
                >
                  help@getcatch.com
                </Link>
                .
              </Typography>
            </Stack>

            <CatchCardDisclosures />
          </Stack>
        </>
      )}
    </Stack>
  );
};

export default CatchCardLinkDebit;
