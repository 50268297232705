import { useDispatch } from "react-redux";

import { AlertModalData, openAlertModal } from "~src/store/slices/core-slice";

type SetKey =
  | "linkExpired"
  | "existingUserCantClaimReferral"
  | "cantClaimOwnGift"
  | "cantClaimGiftedRewardFromAlreadyClaimedRewardCampaign"
  | "unknownErrorClaimingGift"
  | "creditCanOnlyBeClaimedOnce"
  | "creditOnlyForNewUsers"
  | "creditNoLongerAvailable"
  | "creditOnlyForNewCustomers";

const staticAlerts: Partial<Record<SetKey, AlertModalData>> = {
  linkExpired: {
    title: "Oops! That link is expired",
    subtitle:
      "You may have received a more recent link. Otherwise, try logging in again and you'll receive a new one.",
    logoutOnClose: true,
  },
  existingUserCantClaimReferral: {
    title: "Sorry, existing users can't claim a referral bonus",
    subtitle:
      "...but we love the enthusiasm! You can earn bonus credits by referring your friends.",
  },
  cantClaimOwnGift: {
    title: "Sorry, you can't claim your own gift",
    subtitle: "...but we love the enthusiasm!",
  },
  cantClaimGiftedRewardFromAlreadyClaimedRewardCampaign: {
    title: "Sorry, you already got one like this",
    subtitle:
      "This credit came from a special offer that can only be claimed once. You’ve already claimed yours.",
  },
  unknownErrorClaimingGift: {
    title: "Oops! Something went wrong.",
    subtitle:
      "We couldn't claim your gift. Please contact the sender for a new gift link.",
  },
  creditCanOnlyBeClaimedOnce: {
    title: "Sorry, this credit can only be claimed once",
    subtitle: "Looking to earn some extra credit? Try referring a friend.",
  },
  creditOnlyForNewUsers: {
    title: "Sorry, this credit is just for new users",
    subtitle: "Looking to earn some extra credit? Try referring a friend.",
  },
  creditNoLongerAvailable: {
    title: "Sorry, this credit is no longer available",
    subtitle:
      "This campaign has ended, but you can still earn extra credit by referring a friend.",
  },
};

const templateAlerts: Partial<
  Record<SetKey, (...args: string[]) => AlertModalData>
> = {
  creditOnlyForNewCustomers: (merchantName: string) => ({
    title: "Sorry, this credit is just for new customers",
    subtitle: `Looks like you've already shopped at ${merchantName} with Catch.`,
  }),
};

type AlertHook = (...args: string[]) => void;

const useAlertModal = () => {
  const dispatch = useDispatch();

  const staticAlertEntries: [string, AlertHook][] = Object.entries(
    staticAlerts
  ).map(([methodName, modalData]) => [
    methodName,
    () => dispatch(openAlertModal(modalData)),
  ]);

  const templateAlertEntries: [string, AlertHook][] = Object.entries(
    templateAlerts
  ).map(([methodName, modalDataTemplate]) => [
    methodName,
    (...args: string[]) => dispatch(openAlertModal(modalDataTemplate(...args))),
  ]);

  const setAlertModal = Object.fromEntries([
    ...staticAlertEntries,
    ...templateAlertEntries,
  ]) as Record<SetKey, AlertHook>;

  return setAlertModal;
};

export default useAlertModal;
