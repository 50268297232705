import { createSelector } from "@reduxjs/toolkit";

import { UserPortalExperimentNames } from "~common/experimentation/shared";

import { RootState } from "../store";

const selectAuthData = createSelector(
  (state: RootState) => state.user.authData,
  (authData) => authData
);

const selectAuthSession = createSelector(
  (state: RootState) => state.user.authSession,
  (authSession) => authSession
);

const selectEmailVerificationCode = createSelector(
  (state: RootState) => state.user.emailVerificationCode,
  (emailVerificationCode) => emailVerificationCode
);

// The user is considered authed if `user.authData` is not null.
const selectIsAuthed = createSelector(
  (state: RootState) => state.user.authData,
  (authData) => !!authData
);

// The user is fully authenticated and verified if:
// 1. `user.authData` is not null.
// 2. `user.authUserInfo.email` is defined.
// 3. The user's email is verified.
const selectIsAuthedVerified = createSelector(
  (state: RootState) => state.user,
  (user) =>
    user.authData !== null &&
    user.authUserInfo?.email &&
    user.authUserInfo.emailVerified
);

const selectAuthUserInfo = createSelector(
  (state: RootState) => state.user.authUserInfo,
  (authUserInfo) => authUserInfo
);

const selectClaims = createSelector(
  (state: RootState) => state.user.claims,
  (claims) => claims
);

const selectPublicData = createSelector(
  (state: RootState) => state.user.publicData,
  (publicData) => publicData
);

const selectShowLoggedOutNotice = createSelector(
  (state: RootState) => state.user.showLoggedOutNotice,
  (showLoggedOutNotice) => showLoggedOutNotice
);

const selectUserId = createSelector(
  (state: RootState) => state.user.authUserInfo,
  (authUserInfo) => authUserInfo?.userId
);

const selectDisplayRewardBoostDialog = (state: RootState) =>
  state.user.displayRewardBoostDialog &&
  !state.user.dismissedRewardBoostDialog &&
  !state.core.fromRewardBoostEmail;

const selectExperiment = (
  state: RootState,
  experimentName: UserPortalExperimentNames
): string | null => state.user.experiments[experimentName] || null;

const selectCatchCard = (state: RootState) => state.user.catchCard;
const selectAuthPhone = (state: RootState) => state.user.authPhone;

export {
  selectAuthData,
  selectAuthPhone,
  selectAuthSession,
  selectAuthUserInfo,
  selectCatchCard,
  selectClaims,
  selectDisplayRewardBoostDialog,
  selectEmailVerificationCode,
  selectExperiment,
  selectIsAuthed,
  selectIsAuthedVerified,
  selectPublicData,
  selectShowLoggedOutNotice,
  selectUserId,
};
