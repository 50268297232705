import { LoggingMode } from "~common/logging";
import { AppEnvironment, AppStage } from "~common/utils/config-utils";

const APP_ENVIRONMENT = import.meta.env.VITE_APP_ENVIRONMENT as AppEnvironment;
const IS_DEV = APP_ENVIRONMENT === "development";
const IS_PROD = APP_ENVIRONMENT === "production";

const APP_STAGE = import.meta.env.VITE_APP_STAGE as AppStage;
const IS_SANDBOX = APP_STAGE === "sandbox";
const IS_LIVE = APP_STAGE === "live";

const EXTERNAL_ASSETS_BASE_URL = import.meta.env
  .VITE_EXTERNAL_ASSETS_BASE_URL as string;

const SEGMENT_KEY = import.meta.env.VITE_SEGMENT_KEY as string;
const SENTRY_DSN = import.meta.env.VITE_SENTRY_DSN as string;
const STATSIG_KEY = import.meta.env.VITE_STATSIG_KEY as string;

const TELLER_APP_ID = {
  development: "app_o3ro7dirgpukpk616a000",
  staging: "app_o3ro8k2s0pukpk616a000",
  production: "app_n94uebnk3dbatvi76k000",
}[APP_ENVIRONMENT];

// The universal app stage should never apply to user portal
// so it doesn't need a valid Adyen key
const ADYEN_CLIENT_KEY = {
  development: {
    live: "test_GZSVL3FNAJFL5KOJ4CN5XCBUVQKCEJWT",
    sandbox: "test_DPNFYMO2CVCWTCMTPRWTTXRRLM64LGYW",
    universal: "",
  },
  staging: {
    live: "test_LDQ7K54OLFESTN4D27CU35F72MY4OR2Z",
    sandbox: "test_HWNL4RPTHFEX3MW6HWIUADHB7UCVLGJV",
    universal: "",
  },
  production: {
    live: "live_W4YQML7545GZPIVEJ7RGKVMZP4AFKYKV",
    sandbox: "test_RDPMBRFYMFDIRJL3MI4APKCWH4A2YRBR",
    universal: "",
  },
}[APP_ENVIRONMENT][APP_STAGE];

const SMARTY_STREETS_KEY = "110713694802285881";
const ITERABLE_KEY = import.meta.env.VITE_ITERABLE_KEY as string;

const LOGGING_MODE =
  (import.meta.env.VITE_LOGGING_MODE as LoggingMode) || "default";

export {
  ADYEN_CLIENT_KEY,
  APP_ENVIRONMENT,
  APP_STAGE,
  EXTERNAL_ASSETS_BASE_URL,
  IS_DEV,
  IS_LIVE,
  IS_PROD,
  IS_SANDBOX,
  ITERABLE_KEY,
  LOGGING_MODE,
  SEGMENT_KEY,
  SENTRY_DSN,
  SMARTY_STREETS_KEY,
  STATSIG_KEY,
  TELLER_APP_ID,
};
