import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  AdyenCheckoutCallbackData,
  AdyenInputFieldValidity,
} from "../../banking/adyen";
import { CreateAdyenSessionResponseData } from "../../services";

type BankLinkingStep =
  // First party UI steps:
  | "ConnectBank"
  | "BankPicker"
  // Third party UI steps:
  | "TellerLogin"
  | "PlaidLogin"
  | "AdyenConnect";

type ConnectBankVariant =
  | "Default"
  | "Retry"
  | "Reconnect"
  | "ChangeMethod"
  | "ErrorInsufficientFunds"
  | "ErrorExistingAccount"
  | "ErrorIncompatibleAccount"
  | "ErrorAdyenCardNotDebit"
  | "ErrorCardCannotConnect"
  | "ErrorCardVerificationFailed"
  | "ErrorCardNotUS"
  | "ErrorUnsupportedCardBrand"
  | "ErrorExpiredLinkedCard"
  | "ErrorPushToDebit";

type CardVerificationError = "Cvc" | "ExpirationDate" | "Address";

type PlaidCustomLinkName = string | null;
type TellerInstitutionId = string | null;

type BankLinkingState = {
  // Which screen/step of the flow is active.
  step: BankLinkingStep;

  // Which variant of the ConnectBank screen is active.
  connectBankVariant: ConnectBankVariant;

  // Whether the modal UI variants should render.
  bankModalOpen: boolean;

  // Processing state for showing loading screen during a bank link in progress.
  isProcessingLinkUpdate: boolean;

  // Whether the user is reconnecting an already connected instrument.
  isReconnectingPaymentInstrument: boolean;

  // Used to prevent plaid modal from flashing after success in Checkout.
  //
  // Because in Checkout we need to await the refresh instrument step after
  // a successful link, PlaidLink would otherwise re-open the Plaid modal
  // after redirect (e.g. to Complete Purchase or a bank linking error page)
  // but before refresh instrument completes, and thereby the modal would pop
  // back open for a few seconds before closing again.
  //
  // Using this switch, we can prevent the effect hook in PlaidLink from doing
  // this after a successful link.
  plaidOpenAllowed: boolean;

  // Used to open the Plaid Link flow to a specific pre-defined state.
  plaidCustomLinkName: PlaidCustomLinkName;

  // Used to open Teller Connect to a specific pre-defined state.
  tellerInstitutionId: TellerInstitutionId;

  // Disable debit feature flag (we reverse this as Statsig defaults to false while uninitialized)
  disableDebit: boolean | null;
  // Turns off ACH
  disableAch: boolean | null;
  // Session used to initialize the adyen web component
  adyenSession: CreateAdyenSessionResponseData | null;
  // Since we are unable to use Adyen's onSubmit callback, we store the payment method
  // details for a future API call
  currentAdyenCardDetails: AdyenCheckoutCallbackData | null;
  // Used to determine if we need to reload the Adyen component
  adyenReloadNeeded: boolean;
  // Since we cannot unmount the Adyen web component without losing the data within the fields
  // (and we can't store the field data as they are secured fields), while processing a link update
  // we hide the dialog/drawer and overlay in case we need to resurface them
  hideAdyenModal: boolean;
  // Stores specific error for card verification (i.e. address, cvc, or expiration date)
  cardVerificationError: CardVerificationError | null;
  // Stores whether to show an error for the zip code input.
  showZipCodeError: boolean | null;
  // Stores whether the zip code field is actually valid (not whether the error message should be visibile)
  isZipCodeValid: boolean;
  // Stores whether the secure input fields (card number, expiration, CVV) are currently valid
  isDebitSecureInputValid: boolean;
  // Stores the brand of the card so we can show an error screen if it is unsupported
  cardBrand: string | null;
  // Stores the validity of each card field (number, expiration, cvv) for Segment tracking purposes
  cardFieldValidity: AdyenInputFieldValidity | null;
  // Triggers changes needed for the debit form when embedded in the CatchPass mobile flow
  isCatchPassFlow: boolean;
};

const initialState: BankLinkingState = {
  step: "ConnectBank",
  connectBankVariant: "Default",
  bankModalOpen: false,
  isProcessingLinkUpdate: false,
  isReconnectingPaymentInstrument: false,
  plaidOpenAllowed: false,
  plaidCustomLinkName: null,
  tellerInstitutionId: null,
  disableDebit: null,
  disableAch: null,
  adyenSession: null,
  adyenReloadNeeded: true,
  currentAdyenCardDetails: null,
  hideAdyenModal: true,
  cardVerificationError: null,
  showZipCodeError: null,
  isZipCodeValid: false,
  isDebitSecureInputValid: false,
  cardBrand: null,
  cardFieldValidity: null,
  isCatchPassFlow: false,
};

const slice = createSlice({
  name: "bankLinking",
  initialState,
  reducers: {
    resetConnectionState: (state) => {
      state.isProcessingLinkUpdate = false;
      state.isReconnectingPaymentInstrument = false;
      state.plaidCustomLinkName = null;
      state.tellerInstitutionId = null;
      state.currentAdyenCardDetails = null;
      state.hideAdyenModal = true;
      state.adyenSession = null;
      state.isZipCodeValid = false;
      state.showZipCodeError = false;
      state.isDebitSecureInputValid = false;
    },

    setBankLinkingStep: (state, action: PayloadAction<BankLinkingStep>) => {
      state.step = action.payload;
    },

    setConnectBankVariant: (
      state,
      action: PayloadAction<ConnectBankVariant>
    ) => {
      state.connectBankVariant = action.payload;
    },

    openBankModal: (state) => {
      state.bankModalOpen = true;
    },

    closeBankModal: (state) => {
      state.bankModalOpen = false;
    },

    setIsProcessingLinkUpdate: (state, action: PayloadAction<boolean>) => {
      state.isProcessingLinkUpdate = action.payload;
    },

    setIsReconnectingPaymentInstrument: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.isReconnectingPaymentInstrument = action.payload;
    },

    setPlaidOpenAllowed: (state, action: PayloadAction<boolean>) => {
      state.plaidOpenAllowed = action.payload;
    },

    setPlaidCustomLinkName: (
      state,
      action: PayloadAction<PlaidCustomLinkName>
    ) => {
      state.plaidCustomLinkName = action.payload;
    },

    setTellerInstitutionId: (
      state,
      action: PayloadAction<TellerInstitutionId>
    ) => {
      state.tellerInstitutionId = action.payload;
    },

    setDisableDebit: (state, action: PayloadAction<boolean>) => {
      state.disableDebit = action.payload;
    },

    setDisableAch: (state, action: PayloadAction<boolean>) => {
      state.disableAch = action.payload;
    },

    setAdyenSession: (
      state,
      action: PayloadAction<CreateAdyenSessionResponseData | null>
    ) => {
      state.adyenSession = action.payload;
    },

    setAdyenReloadNeeded: (state, action: PayloadAction<boolean>) => {
      state.adyenReloadNeeded = action.payload;
    },

    setCurrentAdyenCardDetails: (
      state,
      action: PayloadAction<AdyenCheckoutCallbackData>
    ) => {
      state.currentAdyenCardDetails = action.payload;
    },

    setHideAdyenModal: (state, action: PayloadAction<boolean>) => {
      state.hideAdyenModal = action.payload;
    },

    setCardVerificationError: (
      state,
      action: PayloadAction<CardVerificationError | null>
    ) => {
      state.cardVerificationError = action.payload;
    },

    setShowZipCodeError: (state, action: PayloadAction<boolean | null>) => {
      state.showZipCodeError = action.payload;
    },

    setIsZipCodeValid: (state, action: PayloadAction<boolean>) => {
      state.isZipCodeValid = action.payload;
    },

    setIsDebitSecureInputValid: (state, action: PayloadAction<boolean>) => {
      state.isDebitSecureInputValid = action.payload;
    },

    setCardBrand: (state, action: PayloadAction<string>) => {
      state.cardBrand = action.payload;
    },

    setCardFieldValidity: (
      state,
      action: PayloadAction<AdyenInputFieldValidity | null>
    ) => {
      state.cardFieldValidity = action.payload;
    },

    setIsCatchPassFlow: (state, action: PayloadAction<boolean>) => {
      state.isCatchPassFlow = action.payload;
    },

    // resetBankStateHard is for wiping the bankLinking slice in cases
    // like logging out of User Portal without refreshing before a new
    // user logs in, so we don't maintain any of the old user's state.
    resetBankStateHard: (state) => {
      state = { ...initialState };
    },
  },
});

export const {
  closeBankModal,
  openBankModal,
  resetBankStateHard,
  resetConnectionState,
  setAdyenReloadNeeded,
  setAdyenSession,
  setBankLinkingStep,
  setCardVerificationError,
  setConnectBankVariant,
  setCurrentAdyenCardDetails,
  setDisableDebit,
  setDisableAch,
  setHideAdyenModal,
  setIsProcessingLinkUpdate,
  setIsReconnectingPaymentInstrument,
  setPlaidOpenAllowed,
  setPlaidCustomLinkName,
  setTellerInstitutionId,
  setShowZipCodeError,
  setIsZipCodeValid,
  setIsDebitSecureInputValid,
  setIsCatchPassFlow,
  setCardBrand,
  setCardFieldValidity,
} = slice.actions;
export default slice.reducer;
export type { BankLinkingState, ConnectBankVariant };
