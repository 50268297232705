import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { Stack, Typography } from "@mui/material";

import { DefaultButton } from "~common/components/controls/buttons";
import { AlertIcon } from "~common/components/icons/vector";
import { DefaultDialog } from "~common/components/modals";
import useAppLogout from "~src/hooks/services/useAppLogout";
import { selectAlertModal } from "~src/store";
import { closeAlertModal } from "~src/store/slices/core-slice";

const AlertModal: React.VFC = () => {
  const dispatch = useDispatch();
  const alertModal = useSelector(selectAlertModal);
  const logout = useAppLogout();

  const handleClose = () => {
    if (alertModal?.logoutOnClose) {
      void logout({ cause: "Logout Alert Modal closed" });
    }
    dispatch(closeAlertModal());
  };

  return (
    <DefaultDialog
      open={alertModal !== null}
      onClose={handleClose}
      ariaLabelledBy="alert-modal-heading"
      ariaDescribedBy="alert-modal-subheading"
    >
      <Stack
        spacing={6}
        alignItems="center"
        sx={{
          textAlign: "center",
        }}
      >
        <AlertIcon size="medium" />
        <Typography variant="h1" id="alert-modal-heading">
          {alertModal?.title}
        </Typography>
        <Typography
          variant="bodyRegular"
          color="grey.600"
          id="alert-modal-subheading"
        >
          {alertModal?.subtitle}
        </Typography>
        <DefaultButton onClick={handleClose} fullWidth>
          Got it
        </DefaultButton>
      </Stack>
    </DefaultDialog>
  );
};

export default AlertModal;
