import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  useClaimGift,
  useClaimRewardCampaign,
  useUpdateHighlightStatus,
} from "~common/services";
import { convertRawServiceError } from "~common/services/error-handling";
import { useTracking } from "~common/tracking";
import useAlertModal from "~src/hooks/useAlertModal";
import { selectClaims, selectIsAuthed, selectUserId } from "~src/store";
import { highlightsActions } from "~src/store/slices/services/highlights-slice";
import { userRewardsActions } from "~src/store/slices/services/userRewards-slice";
import {
  clearCampaignClaim,
  clearGiftClaim,
  setClaimedCampaignStatus,
} from "~src/store/slices/user-slice";
import { giftInfo } from "~src/utils/gifts";

// This hook automatically claims any rewards the user is eligible
// for from claimed gift or reward campaign links.

const useClaimRewards = () => {
  const { trackEvent, trackError } = useTracking();
  const [hasClaimed, setHasClaimed] = useState<boolean>(false);
  const { mutate: claimRewardCampaign } = useClaimRewardCampaign();
  const { mutate: claimGift } = useClaimGift();
  const { mutate: updateHighlightStatus } = useUpdateHighlightStatus();
  const claims = useSelector(selectClaims);
  const userId = useSelector(selectUserId);
  const isAuthed = useSelector(selectIsAuthed);
  const dispatch = useDispatch();
  const setAlertModal = useAlertModal();

  useEffect(() => {
    if (!isAuthed) {
      return;
    }

    const refreshRewards = () => {
      dispatch(userRewardsActions.setStatus("requested"));
      dispatch(highlightsActions.setStatus("requested"));
    };

    const claimRewards = async () => {
      setHasClaimed(true);

      if (claims.giftSecret) {
        const { isOwnGift } = giftInfo(claims.gift ?? null, userId);

        try {
          await claimGift(undefined, {
            pathParams: {
              giftSecret: claims.giftSecret,
            },
          });
          trackEvent("Success claiming gift");
          refreshRewards();
        } catch (rawError) {
          const error = convertRawServiceError(rawError);
          const errorType = error.error_type;

          trackError("useClaimRewards", "Failure claiming gift", { error });

          if (isOwnGift) {
            setAlertModal.cantClaimOwnGift();
          } else if (
            errorType === "GIFT_RECIPIENT_HAS_ALREADY_CLAIMED_REWARD_CAMPAIGN"
          ) {
            setAlertModal.cantClaimGiftedRewardFromAlreadyClaimedRewardCampaign();
          } else {
            setAlertModal.unknownErrorClaimingGift();
          }
        }
        dispatch(clearGiftClaim());
      }

      if (claims.rewardCampaignId && !claims.rewardCampaign?.claimed) {
        try {
          await claimRewardCampaign(
            claims.rewardCampaignClaimSource
              ? {
                  reward_campaign_claim_source:
                    claims.rewardCampaignClaimSource,
                }
              : undefined,
            {
              pathParams: {
                rewardCampaignId: claims.rewardCampaignId,
              },
            }
          );

          if (claims.rewardCampaign?.highlight_id) {
            await updateHighlightStatus(
              { status: "completed" },
              {
                pathParams: {
                  highlightId: claims.rewardCampaign.highlight_id,
                },
              }
            );
          }

          trackEvent("Success claiming campaign reward", {
            rewardCampaignId: claims.rewardCampaignId,
          });

          refreshRewards();

          if (
            (claims.rewardCampaign?.post_claim_cta_url &&
              claims.rewardCampaign?.merchant_id !== "FLEX_MERCHANT_ID") ||
            claims.rewardCampaign?.is_second_chance_bonus
          ) {
            dispatch(setClaimedCampaignStatus(true));
          } else {
            dispatch(clearCampaignClaim());
          }
        } catch (rawError) {
          const error = convertRawServiceError(rawError);

          trackError("useClaimRewards", "Failure claiming campaign reward", {
            error,
          });

          switch (error.error_type) {
            case "REWARD_CAMPAIGN_NEW_USERS_ONLY":
              setAlertModal.creditOnlyForNewUsers();
              break;
            case "REWARD_CAMPAIGN_USER_ALREADY_CLAIMED":
              setAlertModal.creditCanOnlyBeClaimedOnce();
              break;
            default:
              setAlertModal.creditNoLongerAvailable();
          }

          dispatch(clearCampaignClaim());
        }
      }
    };

    if (!hasClaimed && (claims.giftSecret || claims.rewardCampaignId)) {
      void claimRewards();
    }
  }, [
    trackEvent,
    trackError,
    hasClaimed,
    isAuthed,
    userId,
    claims,
    dispatch,
    claimGift,
    claimRewardCampaign,
    setAlertModal,
    updateHighlightStatus,
  ]);
};

export default useClaimRewards;
