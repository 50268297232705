import { RewardCampaignResponseData } from "~common/services";
import { ErrorResponseData } from "~common/services/types/error-handling-types";

const rewardCampaignInfo = (
  rewardCampaign: RewardCampaignResponseData | null,
  error: ErrorResponseData | null
) => {
  const isInvalidCampaign = !!(
    error &&
    (error.error_type === "NOT_FOUND" ||
      error.error_type === "REWARD_CAMPAIGN_NOT_ACTIVE")
  );

  const isExpiredCampaign = !!(
    error &&
    (error.error_type === "REWARD_CAMPAIGN_LINK_EXPIRED" ||
      error.error_type === "REWARD_CAMPAIGN_CLAIM_LIMIT_REACHED")
  );

  const isOnlyForNewUsers = !!(
    !error &&
    rewardCampaign &&
    rewardCampaign.eligible_user_type === "new_users_only"
  );

  const isFlexCredit = !!(
    !error &&
    rewardCampaign &&
    rewardCampaign.merchant_id === "FLEX_MERCHANT_ID"
  );

  const isSecondChanceBonus = !!(
    !error &&
    rewardCampaign &&
    rewardCampaign.is_second_chance_bonus
  );

  return {
    isInvalidCampaign,
    isExpiredCampaign,
    isOnlyForNewUsers,
    isFlexCredit,
    isSecondChanceBonus,
  };
};

export { rewardCampaignInfo };
