import React from "react";

import useIdResolver from "../../../hooks/useIdResolver";

const SVG: React.VFC = () => {
  const idResolver = useIdResolver();

  return (
    <svg
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M33.7046 26.091L3.99481 59.6706C3.37065 60.4196 3.99481 61.543 4.86862 61.4182L48.5595 56.1753C49.3084 56.0505 49.6829 55.3015 49.3084 54.6773L35.4522 26.3407C35.0777 25.5917 34.2039 25.4669 33.7046 26.091Z"
        stroke="#232639"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M50.9316 34.953C53.5531 35.2027 57.298 32.4564 58.2967 29.0859C58.2967 29.0859 58.4215 35.702 63.1651 36.3261C63.1651 36.3261 56.6739 37.0751 56.1745 42.6925C56.1745 42.8173 57.1732 35.702 50.9316 34.953Z"
        stroke="#232639"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M57.0479 15.6045L61.7914 23.9682"
        stroke="#232639"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M54.8008 23.2193L64.1631 17.4771"
        stroke="#232639"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M46.5625 13.8573C46.4377 13.7325 46.3129 13.7325 46.188 13.8573L40.9451 17.8519C40.8203 17.8519 40.8203 17.9767 40.8203 18.1015C40.8203 18.2264 40.8203 18.3512 40.9451 18.3512C42.4431 20.2237 44.0659 24.7176 42.3183 29.0867C42.3183 29.2115 42.3183 29.3363 42.3183 29.4612C42.4431 29.586 42.5679 29.586 42.6928 29.586L48.3102 29.3363H48.435C48.5598 29.3363 48.5598 29.2115 48.5598 29.2115C48.6847 29.0867 50.8068 24.5928 49.3088 19.8492C47.8108 14.8559 46.6874 13.8573 46.5625 13.8573Z"
        stroke="#232639"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M66.0357 47.6856C63.1645 48.4346 63.0397 48.3098 60.7927 46.4373C60.5431 46.1877 60.1686 45.938 59.9189 45.6883C59.7941 45.5635 59.5444 45.5635 59.4196 45.6883L55.6747 49.8078C55.5498 49.9326 55.5498 50.0574 55.6747 50.1823C57.0478 52.6789 59.2948 53.5527 61.6666 52.8037C64.4128 51.9299 66.2853 52.5541 68.0329 55.1755C68.0329 55.3003 68.1578 55.3003 68.2826 55.3003H68.4074C68.4074 55.3003 68.5323 55.3003 68.5323 55.1755L71.5282 51.3057C71.653 51.1809 71.653 51.0561 71.5282 50.9312C71.653 51.0561 70.0302 46.5622 66.0357 47.6856Z"
        stroke="#232639"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <mask
        id={idResolver("mask0_3463_10215")}
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="2"
        y="24"
        width="49"
        height="39"
      >
        <path
          d="M33.7046 26.0901L3.99481 59.6696C3.37065 60.4186 3.99481 61.5421 4.86862 61.4172L48.5595 56.1743C49.3084 56.0495 49.6829 55.3005 49.3084 54.6764L35.4522 26.3397C35.0777 25.5907 34.2039 25.4659 33.7046 26.0901Z"
          fill="#C4C4C4"
          stroke="#232638"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </mask>
      <g mask={`url(#${idResolver("mask0_3463_10215")})`}>
        <path
          d="M40.945 61.6669C41.0699 58.4213 39.6967 57.6723 37.5746 55.4253C35.4525 53.1784 38.4484 50.5569 35.3277 47.4361C32.2069 44.3154 29.8351 46.812 28.3371 42.6926C26.8391 38.5731 25.2163 37.3248 23.219 36.7007L16.2285 40.8201C18.2258 41.4443 19.9734 42.6926 21.3466 46.812C22.8446 50.9314 25.2163 48.4348 28.3371 51.5556C31.4579 54.6763 28.5868 57.2978 30.5841 59.5447C32.5814 61.7917 34.0793 62.5407 33.9545 65.7863L40.945 61.6669Z"
          stroke="#232639"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <path
        d="M66.9092 30.9589L74.5239 26.5898"
        stroke="#232639"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M69.2812 36.4512L77.0208 38.6981"
        stroke="#232639"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SVG;
