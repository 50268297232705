import React from "react";
import { useIntercom } from "react-use-intercom";

import { Stack, StackProps, Typography } from "@mui/material";

import { DefaultButton } from "~common/components/controls/buttons";
import { AlertIcon, MeditatingCoinIcon } from "~common/components/icons/vector";
import { useTracking } from "~common/tracking";

type CatchCardErrorProps = StackProps & {
  showIcon?: boolean;
  isPending?: boolean;
};

const CatchCardError: React.VFC<CatchCardErrorProps> = ({
  showIcon,
  isPending,
  ...props
}) => {
  const intercom = useIntercom();
  const { trackEvent } = useTracking();

  const handleClick = () => {
    trackEvent("Catch Card Contact us clicked");
    intercom.show();
  };

  return (
    <Stack spacing={8} color="grey.600" {...props}>
      <Stack spacing={2} alignItems="center" textAlign="center">
        {showIcon && !isPending && <AlertIcon size="small" />}
        {showIcon && isPending && <MeditatingCoinIcon size="small" />}

        <Typography variant="h2" component="h3">
          {isPending
            ? "Hang tight, you're in the queue!"
            : "Sorry, we weren't able to activate your Catch card."}
        </Typography>

        {isPending ? (
          <Stack spacing={8}>
            <Typography variant="bodyRegular">
              Due to a high volume of applications, expect your card in 1-3
              business days. We&apos;ll let you know as soon as it&apos;s ready.
            </Typography>

            <Typography variant="bodyRegular">
              While you wait, go claim this week&apos;s drops!
            </Typography>
          </Stack>
        ) : (
          <Typography variant="bodyRegular">
            There are a few reasons this might&apos;ve happened. You can get in
            touch with our team for more information.
          </Typography>
        )}
      </Stack>

      {!isPending && (
        <DefaultButton onClick={handleClick}>Contact us</DefaultButton>
      )}
    </Stack>
  );
};

export default CatchCardError;
