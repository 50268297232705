import { useGet, useMutate } from "restful-react";

import { ErrorResponseData } from "./types/error-handling-types";
import {
  AnywhereCardApplicationRequestData,
  AnywhereCardApplicationResponseData,
  AnywhereCardResponseData,
  AnywhereCardUpdatePinPathParams,
  AnywhereCardUpdatePinRequestData,
} from "./types/issued-cards-types";

const useGetAnywhereCardForUser = (options?: { lazy?: boolean }) =>
  useGet<AnywhereCardResponseData, ErrorResponseData, undefined, undefined>({
    path: "issued-cards-svc/anywhere-card",
    lazy: options?.lazy,
  });

const useAnywhereCardApplications = () =>
  useMutate<
    AnywhereCardApplicationResponseData,
    ErrorResponseData,
    undefined,
    AnywhereCardApplicationRequestData,
    unknown
  >({
    verb: "POST",
    path: "issued-cards-svc/anywhere-card-applications",
  });

const useUpdateAnywhereCardPin = () =>
  useMutate<
    AnywhereCardResponseData,
    ErrorResponseData,
    undefined,
    AnywhereCardUpdatePinRequestData,
    AnywhereCardUpdatePinPathParams
  >({
    verb: "PATCH",
    path: ({ issuedCardId }) =>
      `issued-cards-svc/anywhere-card/${issuedCardId}/pin`,
  });

export {
  useAnywhereCardApplications,
  useGetAnywhereCardForUser,
  useUpdateAnywhereCardPin,
};
