import React from "react";
import { useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";

import CustomStatsigProvider from "~common/components/providers/CustomStatsigProvider";
import HeadlessLayout from "~src/components/layout/HeadlessLayout";
import Layout from "~src/components/layout/Layout";
import AppRouteHandler from "~src/components/routing/AppRouteHandler";
import { IS_DEV } from "~src/config";
import useAppEffects from "~src/hooks/useAppEffects";
import * as Page from "~src/pages";

import Experiments from "./components/main/Experiments";
import useMobileAppTracking from "./hooks/useMobileAppTracking";
import { selectAuthPhone } from "./store";

const App: React.VFC = () => {
  useMobileAppTracking();
  useAppEffects();
  const authPhone = useSelector(selectAuthPhone);

  return (
    <CustomStatsigProvider appName="user-portal">
      <Experiments>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route
              index
              element={
                <AppRouteHandler>
                  <Page.NotFound />
                </AppRouteHandler>
              }
            />
            <Route
              path="home"
              element={
                <AppRouteHandler authRequired>
                  <Page.Home />
                </AppRouteHandler>
              }
            />
            <Route
              path="shop"
              element={
                <AppRouteHandler authRequired>
                  <Page.Shop />
                </AppRouteHandler>
              }
            />
            <Route
              path="account"
              element={
                <AppRouteHandler authRequired>
                  <Page.Account />
                </AppRouteHandler>
              }
            />
            <Route
              path="payment-linking"
              element={
                <AppRouteHandler>
                  <Page.LinkPaymentMethodMobile />
                </AppRouteHandler>
              }
            />
            <Route
              path="g/:giftSecret"
              element={
                <AppRouteHandler>
                  <Page.LandingGift />
                </AppRouteHandler>
              }
            />
            <Route
              path="r/:referralCode"
              element={
                <AppRouteHandler>
                  <Page.LandingReferral />
                </AppRouteHandler>
              }
            />
            <Route
              path="e/:rewardCampaignId"
              element={
                <AppRouteHandler>
                  <Page.LandingCampaign />
                </AppRouteHandler>
              }
            />
            <Route
              path="verify/email/:emailVerificationCode"
              element={
                <AppRouteHandler>
                  <Page.Verify.Email />
                </AppRouteHandler>
              }
            />
            <Route path="sign-in">
              <Route
                index
                element={
                  <AppRouteHandler authFlow>
                    {authPhone ? (
                      <Page.Auth.SkipSignIn />
                    ) : (
                      <Page.Auth.SignIn />
                    )}
                  </AppRouteHandler>
                }
              />
              <Route
                path="answer-otp"
                element={
                  <AppRouteHandler authFlow>
                    <Page.Auth.AnswerOTP />
                  </AppRouteHandler>
                }
              />
              <Route
                path="complete-account"
                element={
                  <AppRouteHandler authFlow>
                    <Page.Auth.CompleteAccount />
                  </AppRouteHandler>
                }
              />
              <Route
                path="verify-email"
                element={
                  <AppRouteHandler authFlow>
                    <Page.Auth.VerifyEmail />
                  </AppRouteHandler>
                }
              />
            </Route>

            {IS_DEV && (
              <Route path="internal">
                <Route
                  path="quick"
                  element={
                    <AppRouteHandler>
                      <Page.Internal.QuickStart />
                    </AppRouteHandler>
                  }
                />
              </Route>
            )}

            <Route
              path="*"
              element={
                <AppRouteHandler>
                  <Page.NotFound />
                </AppRouteHandler>
              }
            />
          </Route>

          <Route path="/ext" element={<HeadlessLayout />}>
            <Route
              path="shop"
              element={
                <AppRouteHandler>
                  <Page.External.PublicShop />
                </AppRouteHandler>
              }
            />
            <Route
              path="sign-up"
              element={
                <AppRouteHandler>
                  <Page.External.SignUp />
                </AppRouteHandler>
              }
            />
          </Route>
        </Routes>
      </Experiments>
    </CustomStatsigProvider>
  );
};

export default App;
