import React, { useEffect, useState } from "react";
import Confetti from "react-confetti";
import { useSelector } from "react-redux";

import {
  ArrowForward as ArrowForwardIcon,
  Check as CheckIcon,
} from "@mui/icons-material";
import {
  Box,
  FormHelperText,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";

import { PrimaryButton } from "~common/components/controls/buttons";
import { MARQETA_ERROR_MESSAGE } from "~common/components/marqeta/marqeta";
import MarqetaCard from "~common/components/marqeta/MarqetaCard";
import { useTracking } from "~common/tracking";
import { IS_DEV } from "~src/config";
import { selectCatchCard } from "~src/store";

type CatchCardSuccessProps = {
  onNext: () => void;
};

const CatchCardSuccess: React.VFC<CatchCardSuccessProps> = ({ onNext }) => {
  const { trackEvent, trackError } = useTracking();
  const { palette, shape } = useTheme();
  const catchCard = useSelector(selectCatchCard);
  const [showCard, setShowCard] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    trackEvent("CatchPass Application Succeeded");
  }, [trackEvent]);

  useEffect(() => {
    if (catchCard?.marqeta_token) {
      setTimeout(() => setShowCard(true), 1000);
    }
  }, [catchCard]);

  const handleClick = () => {
    trackEvent("Catch Card Choose your PIN Clicked");
    onNext();
  };

  const handleError = () => {
    setError(MARQETA_ERROR_MESSAGE);
    trackError("Catch Card Activation", "Marqeta bootstrap error");
  };

  const handleCopy = (name: string) => {
    trackEvent(`Catch Card Copy ${name} Clicked`);
  };

  if (!showCard) {
    return (
      <Stack alignItems="center">
        <Stack
          width={48}
          height={48}
          borderRadius="50%"
          alignItems="center"
          justifyContent="center"
          bgcolor="primary.main"
          color="common.white"
        >
          <CheckIcon
            sx={{
              "&.MuiSvgIcon-root": {
                display: "block",
                fontSize: 36,
              },
            }}
          />
        </Stack>
      </Stack>
    );
  }

  return (
    <>
      <Box
        position="absolute"
        overflow="hidden"
        sx={{
          inset: 0,
        }}
      >
        <Confetti
          colors={[
            palette.tertiary.main,
            palette.primary.main,
            palette.primary.light,
          ]}
          initialVelocityY={5}
          numberOfPieces={300}
          recycle={false}
        />
      </Box>

      <Stack spacing={6} alignItems="center" position="relative" zIndex={3}>
        <Box
          width="100%"
          maxWidth={327}
          borderRadius={`${shape.borderRadius * 5}px`}
          overflow="hidden"
        >
          <MarqetaCard
            token={catchCard?.marqeta_token || ""}
            isSandbox={IS_DEV}
            onFailure={handleError}
            onCopy={handleCopy}
          />
        </Box>

        {error && <FormHelperText error>{error}</FormHelperText>}

        <Stack spacing={4} textAlign="center">
          <Typography variant="h1" component="h3">
            Meet your new virtual Catch card!
          </Typography>

          <Typography>
            View it any time in our app or browser extension. You can also copy
            the details anywhere you save payment methods.
          </Typography>
        </Stack>

        <PrimaryButton
          onClick={handleClick}
          endIcon={<ArrowForwardIcon />}
          fullWidth
        >
          Choose your PIN
        </PrimaryButton>
      </Stack>
    </>
  );
};

export default CatchCardSuccess;
