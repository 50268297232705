import React from "react";

import { Card, CardProps, Stack } from "@mui/material";

import { useReduceMotion } from "../../hooks/accessibility-hooks";

type FlippableCardProps = CardProps & {
  front: React.ReactNode;
  back: React.ReactNode;
  flipped: boolean;
};

const FlippableCard: React.VFC<FlippableCardProps> = ({
  front,
  back,
  flipped,
  ...props
}) => {
  const reduceMotion = useReduceMotion();

  return (
    <Card
      {...props}
      sx={({ palette, shape }) => ({
        background: "transparent",
        overflow: "visible",
        transformStyle: "preserve-3d",
        transition: reduceMotion ? "none" : "transform 1s",
        transform: `rotateY(${flipped ? 180 : 0}deg)`,
        borderRadius: `${shape.borderRadius * 4}px`,
        "& > div": {
          background: palette.background.default,
          backfaceVisibility: "hidden",
          borderRadius: "inherit",
          overflow: "hidden",
          transform: "translateZ(0)",
        },
        "& > div:first-of-type": {
          transition: `visibility 0s linear ${
            flipped && !reduceMotion ? "1s" : "0s"
          }`,
          visibility: flipped ? "hidden" : "visible",
        },
        "& > div:last-of-type": {
          position: "absolute",
          inset: 0,
          transform: "rotateY(180deg)",
          transition: `visibility 0s linear ${
            flipped || reduceMotion ? "0s" : "1s"
          }`,
          visibility: flipped ? "visible" : "hidden",
        },
      })}
    >
      <Stack justifyContent="center">{front}</Stack>
      <Stack justifyContent="center">{back}</Stack>
    </Card>
  );
};

export default FlippableCard;
