// GENERATED FILE. DO NOT EDIT.

import React from "react";

import SmallSVG from "../vector-source/EducationalContent.small.svg";
import VectorIcon, { VectorIconBaseProps } from "../VectorIcon";

const EducationalContentIcon: React.VFC<VectorIconBaseProps> = (props) => (
  <VectorIcon smallSvg={SmallSVG} {...props} />
);

export default EducationalContentIcon;
