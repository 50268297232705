import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Lock as LockIcon } from "@mui/icons-material";
import {
  Box,
  FormHelperText,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { DateField } from "@mui/x-date-pickers/DateField";
import { Dayjs } from "dayjs";

import { PrimaryButton } from "~common/components/controls/buttons";
import { useUpdateCurrentUser } from "~common/services/users";
import { useTracking } from "~common/tracking";
import {
  EMPTY_FIRST_NAME_MESSAGE,
  EMPTY_LAST_NAME_MESSAGE,
  trimName,
} from "~common/utils/names";
import { selectCurrentUser } from "~src/store";
import { currentUserActions } from "~src/store/slices/services/currentUser-slice";
import {
  currentUserHasBirthday,
  EMPTY_DOB_MESSAGE,
  getCurrentUserBirthday,
  getDayjsBirthday,
} from "~src/utils/dayjs";

type CatchCardContactFormProps = {
  onNext: () => void;
};

const CatchCardContactForm: React.VFC<CatchCardContactFormProps> = ({
  onNext,
}) => {
  const dispatch = useDispatch();
  const { trackEvent, trackError } = useTracking();
  const currentUser = useSelector(selectCurrentUser.data);
  const { mutate: updateCurrentUser } = useUpdateCurrentUser({
    queryParams: { include_billing_address: true },
  });
  const [firstName, setFirstName] = useState(currentUser?.first_name || "");
  const [lastName, setLastName] = useState(currentUser?.last_name || "");
  const [dob, setDob] = useState<Dayjs | null>(getDayjsBirthday(currentUser));
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<Record<string, string>>({});

  const handleChange = (setState: () => void) => {
    setState();
    setErrors({});
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    const validatedData = handleValidation();

    if (validatedData.errors) {
      setErrors(validatedData.errors);
      return;
    }

    setLoading(true);

    try {
      await updateCurrentUser(validatedData.body).then((response) => {
        dispatch(currentUserActions.manualSet(response));
      });

      trackEvent("Catch Card Contact Info Saved");
      onNext();
    } catch (err) {
      setLoading(false);
      trackError("Catch Card Activation", "Contact info", { error: err });

      setErrors({
        generic: "Something didn't work as expected. Please try again.",
      });
    }
  };

  const handleValidation = () => {
    const parsedFirstName = trimName(firstName);
    const parsedLastName = trimName(lastName);
    const parsedDob = getCurrentUserBirthday(dob);

    if (parsedFirstName && parsedLastName && parsedDob) {
      return {
        body: {
          first_name: parsedFirstName,
          last_name: parsedLastName,
          ...parsedDob,
        },
      };
    }

    return {
      errors: {
        ...(!parsedFirstName && {
          firstName: EMPTY_FIRST_NAME_MESSAGE,
        }),
        ...(!parsedLastName && {
          lastName: EMPTY_LAST_NAME_MESSAGE,
        }),
        ...(!parsedDob && {
          dob: EMPTY_DOB_MESSAGE,
        }),
      },
    };
  };

  return (
    <Stack spacing={6} component="form" onSubmit={handleSubmit}>
      <Typography pb={2}>
        First, let&apos;s make sure we have up-to-date contact information.
      </Typography>

      <TextField
        label="First name"
        value={firstName}
        error={!!errors.firstName}
        helperText={
          <Box component="span" mb={-2} display="block">
            {errors.firstName}
          </Box>
        }
        onChange={(e) => handleChange(() => setFirstName(e.target.value))}
        autoFocus
      />

      <TextField
        label="Last name"
        value={lastName}
        error={!!errors.lastName}
        helperText={
          <Box component="span" mb={-2} display="block">
            {errors.lastName}
          </Box>
        }
        onChange={(e) => handleChange(() => setLastName(e.target.value))}
      />

      <DateField
        label="Date of birth"
        value={dob}
        disabled={currentUserHasBirthday(currentUser)}
        InputProps={{
          error: !!errors.dob,
        }}
        InputLabelProps={{
          color: errors.dob ? "error" : "primary",
          error: !!errors.dob,
        }}
        helperText={
          <Box component="span" color="error.main" mb={-2} display="block">
            {errors.dob}
          </Box>
        }
        onChange={(value) => handleChange(() => setDob(value))}
      />

      {errors.generic && (
        <FormHelperText error>{errors.generic}</FormHelperText>
      )}

      <PrimaryButton type="submit" startIcon={<LockIcon />} loading={loading}>
        Continue
      </PrimaryButton>
    </Stack>
  );
};

export default CatchCardContactForm;
